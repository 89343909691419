import React from 'react'
import { Button, Container, CardDeck, Card, Row, Col, OverlayTrigger, Tooltip, Alert} from 'react-bootstrap'
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaCheck, FaQuestion } from "react-icons/fa"
import Img from "gatsby-image" 
import ReactMarkdown from 'react-markdown'

const Page = ({ data }) => {
    const { mdx } = data
    const { frontmatter } = mdx

    return (
      <Layout>
        <SEO title="Fonctionnement et tarifs" />
        <Container className="bg-green-light my-md-4 py-4 px-4 rounded text-justify h5">

          <h2 className="text-center">Fonctionnement</h2>
          <Row className="justify-content-center text-justify align-items-center mb-md-3 mt-2 py-4">
                  <Col md={4} sm={8} className="mr-0">
                      <FaQuestion size="4em" className="mx-auto d-block"></FaQuestion>
                      <Img fluid={frontmatter.intro_image.childImageSharp.fluid} alt="animal" />
                  </Col>
                  <Col md={6} className="h5">
                    <ReactMarkdown parserOptions={{ commonmark: true }} source={frontmatter.intro} />
                  </Col>
          </Row>

          <ReactMarkdown parserOptions={{ commonmark: true }} source={frontmatter.operation} />
          {
            frontmatter.text_info ? (
              <Alert className="alert-green text-dark">
                <ReactMarkdown parserOptions={{ commonmark: true }} source={frontmatter.text_info} />
              </Alert>
            ) : ''
          }

          <h2 className="text-center mt-4">Tarifs</h2>
          <CardDeck className="mb-3 mt-4 text-center">
            <Card>
              <Card.Header className="bg-grey h4 text-green-dark text-center">Ponctuel</Card.Header>
              <Card.Body>
                <Card.Title><h1>17.5€/demi-journée</h1></Card.Title>
                <ul className="text-left list-unstyled mt-3 mb-4 mx-3">
                  <li><FaCheck className="mr-2" />Tarif du mardi au dimanche</li>
                  <li><FaCheck className="mr-2" />17,5€/demi-journée par enfant</li>
                  <li><FaCheck className="mr-2" />Le créneau doit être réservé préalablement</li>
                  <li><FaCheck className="mr-2" />L'annulation d'une réservation est facturée 1€</li>
                </ul>
              </Card.Body>
              <Card.Footer>
                <Link to='/reservations'><Button>Réserver</Button></Link>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Header className="bg-grey h4 text-green-dark text-center">Cartes multi-accueils</Card.Header>
              <Card.Body>
                <Card.Title><h1>A partir de 80€</h1></Card.Title>
                  <ul className="text-left list-unstyled mt-3 mb-4 mx-3">
                    <li><FaCheck className="mr-2" />Réservez avec votre carte un créneau du mardi au dimanche</li>
                    <li><FaCheck className="mr-2" />Le créneau doit être réservé préalablement</li>
                    <li><FaCheck className="mr-2" />Annulation gratuite de créneau</li>
                    <li><FaCheck className="mr-2" />Valide plusieurs mois après la première utilisation</li>
                  </ul>
              </Card.Body>
              <Card.Footer>
                <Link to='/cartesacces'><Button>Commander</Button></Link>
              </Card.Footer>
            </Card>
          </CardDeck>
        </Container>
      </Layout>
    )
  }  

export default Page

export const pageQuery = graphql`
    query($id: String) {
      mdx(id: { eq: $id }) {
        frontmatter {
          intro
          operation
          text_info
          intro_image {
            childImageSharp {
              fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }`
